import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from 'components/Authentication/AuthContext';
import { LoginPage, RegisterPage } from './components/Authentication/AuthPage';
import Home from './Routes/Home';
import Prize from 'Routes/Prize';
import Draw from 'Routes/Draw';
import Box from 'Routes/Box';
import Promo from 'Routes/Promo';
import Links from 'Routes/Links';
import ServerStatus from 'Routes/ServerStatus';
import Settings from 'Routes/Settings';
import ProfilEditor from 'Routes/Profil';
import UserAll from 'Routes/Admin/UserEdit';
import EmailWL from 'Routes/Admin/EmailWL';
import PromoCodeAdmin from 'components/Admin/PromoCodeAdmin';
import PromoCodeAdminRoute from 'Routes/Admin/PromoCodeAdmin';
import BotConfig from 'Routes/Admin/BotSettings';

// Styles pour masquer la barre de défilement
const hideScrollbarStyles = `
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

// Protected Route Component
interface ProtectedRouteProps {
  element: React.ComponentType<any>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  isMobile: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element: Component,
  isSidebarOpen,
  setIsSidebarOpen,
  isMobile,
}) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} isMobile={isMobile} />;
};

const App: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ajouter les styles pour masquer la barre de défilement
    const styleElement = document.createElement('style');
    styleElement.textContent = hideScrollbarStyles;
    document.head.appendChild(styleElement);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute
                element={Home}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/prize"
            element={
              <ProtectedRoute
                element={Prize}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
           <Route
            path="/settings"
            element={
              <ProtectedRoute
                element={Settings}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
            <Route
            path="/profil"
            element={
              <ProtectedRoute
                element={ProfilEditor}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
            <Route
            path="/admin/users"
            element={
              <ProtectedRoute
                element={UserAll}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
            <Route
            path="/admin/email"
            element={
              <ProtectedRoute
                element={EmailWL}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />

<Route
            path="/admin/promo-code"
            element={
              <ProtectedRoute
                element={PromoCodeAdminRoute}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/admin/bot-settings"
            element={
              <ProtectedRoute
                element={BotConfig}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
             <Route
            path="/boxes"
            element={
              <ProtectedRoute
                element={Box}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
               <Route
            path="/promo-code"
            element={
              <ProtectedRoute
                element={Promo}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
                  <Route
            path="/link"
            element={
              <ProtectedRoute
                element={Links}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
              <Route
            path="/status"
            element={
              <ProtectedRoute
                element={ServerStatus}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />
          <Route
            path="/draw"
            element={
              <ProtectedRoute
                element={Draw}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobile={isMobile}
              />
            }
          />


          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;