  import React, { useEffect, useMemo, useState } from 'react';
  import { motion, AnimatePresence } from 'framer-motion';
  import {
    Mail,
    Shield,
    Search,
    Trash,
    Edit,
    X,
    Save,
    XCircle,
    Plus,
    CheckCircle,
    AlertTriangle,
    DollarSign,
    Tag
  } from 'lucide-react';
import SavePromoDialog, { PromoCodeType } from 'components/SavedPromoDialog';
import { API_URL } from 'components/api_config';
import SavePromoDialogAdmin from './PromoCodeModal/SavePromoAdmin';

interface PromoCode {
  username: string;
  id: number;
  code: string;
  value: number;
  type: string;
  used: boolean;
  createdAt: Date | undefined;
}

interface PromoCodeTableProps {
  promoCodes: PromoCode[];
  onDeleteClick: (id: number) => void;
}

export const PromoCodeTable: React.FC<PromoCodeTableProps> = ({
  promoCodes,
  onDeleteClick,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPromoCodes = promoCodes.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(promoCodes.length / itemsPerPage);

  // Pagination handlers
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700/50">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-700/50">
              <th className="text-left p-4 text-gray-400 font-medium">Code</th>
              <th className="text-left p-4 text-gray-400 font-medium">Value</th>
              <th className="text-left p-4 text-gray-400 font-medium">Type</th>
              <th className="text-left p-4 text-gray-400 font-medium">Status</th>
              <th className="text-left p-4 text-gray-400 font-medium">Assigned to</th>
              <th className="text-left p-4 text-gray-400 font-medium">Created At</th>
              <th className="text-right p-4 text-gray-400 font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentPromoCodes.length === 0 ? (
              <tr>
                <td colSpan={7} className="text-center p-4 text-gray-400">
                  No promo codes found
                </td>
              </tr>
            ) : (
              currentPromoCodes.map((promoCode) => (
                <motion.tr
                  key={promoCode.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  whileHover={{ backgroundColor: 'rgba(255,255,255,0.02)' }}
                  className="border-b border-gray-700/50 last:border-0"
                >
                  <td className="p-4">
                    <div className="flex items-center">
                      <span className="font-mono text-gray-200">{promoCode.code}</span>
                    </div>
                  </td>
                  <td className="p-4">
                    <span className="text-gray-200">{promoCode.value}€</span>
                  </td>
                  <td className="p-4">
                    <span
                      className="px-2 py-1 rounded-full text-xs font-medium"
                      style={{
                        backgroundColor:
                          promoCode.type === 'percentage'
                            ? 'rgba(59, 130, 246, 0.1)'
                            : 'rgba(139, 92, 246, 0.1)',
                        color: promoCode.type === 'percentage' ? '#60A5FA' : '#A78BFA',
                      }}
                    >
                      {promoCode.type}
                    </span>
                  </td>
                  <td className="p-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium ${
                        promoCode.used
                          ? 'bg-red-500/10 text-red-400'
                          : 'bg-green-500/10 text-green-400'
                      }`}
                    >
                      {promoCode.used ? 'Used' : 'Available'}
                    </span>
                  </td>
                  <td className="p-4">
                    <span className="text-gray-200">
                      {promoCode.username || 'N/A'}
                    </span>
                  </td>
                  <td className="p-4 text-gray-400">
                    {promoCode.createdAt
                      ? new Date(promoCode.createdAt).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })
                      : 'N/A'}
                  </td>
                  <td className="p-4">
                    <div className="flex items-center gap-2 justify-end">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => onDeleteClick(promoCode.id)}
                        className="text-red-400 hover:text-red-300 transition-colors"
                      >
                        <Trash size={20} />
                      </motion.button>
                    </div>
                  </td>
                </motion.tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="px-4 py-3 border-t border-gray-700/50">
        <div className="flex justify-between items-center">
          <div className="text-sm text-gray-400">
            Showing {currentPromoCodes.length} of {promoCodes.length} results
          </div>
          <div className="flex gap-2 items-center">
            <motion.button
              onClick={prevPage}
              disabled={currentPage === 1}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className={`px-3 py-1 rounded ${
                currentPage === 1
                  ? 'text-gray-500 cursor-not-allowed'
                  : 'text-gray-200 hover:bg-gray-700/50'
              }`}
            >
              Previous
            </motion.button>
            <span className="text-sm text-gray-400">
              Page {currentPage} of {totalPages}
            </span>
            <motion.button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className={`px-3 py-1 rounded ${
                currentPage === totalPages
                  ? 'text-gray-500 cursor-not-allowed'
                  : 'text-gray-200 hover:bg-gray-700/50'
              }`}
            >
              Next
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

    

    interface PromoCode {
      id: number;
      code: string;
      value: number;
      usage: number;
      used: boolean; 
      status: 'active' | 'used' | 'expired';
      requirements: string;
      boxSlug?: string | null;
      usedBy?: string;
      usedAt?: Date;
    }
    


  interface DeleteConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
  }

  interface StatsCardsProps {
    stats: {
      totalCodes: number;
      usedCodes: number;
      availableCodes: number;
      totalValue: number;
    };
  }
  
  export const StatsCards: React.FC<StatsCardsProps> = ({ stats }) => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          title="Total Codes"
          value={stats.totalCodes}
          icon={<Tag className="w-6 h-6" />}
          color="blue"
        />
        <StatCard
          title="Used Codes"
          value={stats.usedCodes}
          icon={<XCircle className="w-6 h-6" />}
          color="red"
        />
        <StatCard
          title="Available Codes"
          value={stats.availableCodes}
          icon={<CheckCircle className="w-6 h-6" />}
          color="green"
        />
        <StatCard
          title="Total Value"
          value={`${stats.totalValue}€`}
          icon={<DollarSign className="w-6 h-6" />}
          color="purple"
        />
      </div>
    );
  };
  
  interface StatCardProps {
    title: string;
    value: string | number;
    icon: React.ReactNode;
    color: string;
  }

  const StatCard: React.FC<StatCardProps> = ({ title, value, icon, color }) => {
    return (
      <motion.div
        whileHover={{ scale: 1.02 }}
        className="bg-gray-800/50 p-6 rounded-xl border border-gray-700/50"
      >
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">{title}</p>
            <p className="text-2xl font-semibold text-white mt-1">{value}</p>
          </div>
          <div className={`text-${color}-500`}>{icon}</div>
        </div>
      </motion.div>
    );
  };


  const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ 
    isOpen, 
    onClose, 
    onConfirm 
  }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
            >
              <div className="flex flex-col items-center space-y-4 text-center">
                <div className="bg-red-500/10 p-4 rounded-full">
                  <Trash className="text-red-400" size={40} />
                </div>
                
                <div>
                  <h2 className="text-xl font-semibold text-white mb-2">
                    Delete Email
                  </h2>
                  <p className="text-gray-400 text-sm">
                    Are you sure you want to remove this email from the list? 
                    This action cannot be undone.
                  </p>
                </div>

                <div className="flex space-x-4 w-full pt-4">
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onClose}
                    className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-center gap-2"
                  >
                    <XCircle size={20} /> Cancel
                  </motion.button>
                  <motion.button 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onConfirm}
                    className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors flex items-center justify-center gap-2"
                  >
                    <Trash size={20} /> Delete
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  interface SearchBarProps {
    onSearch: (searchTerm: string) => void;
  }
  
  export const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    return (
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search promo codes..."
          onChange={(e) => onSearch(e.target.value)}
          className="pl-10 pr-4 py-2 bg-gray-800/50 border border-gray-700/50 rounded-lg text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        />
      </div>
    );
  };


  const PromoCodeAdmin: React.FC = () => {
    const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
    const [filteredCodes, setFilteredCodes] = useState<PromoCode[]>([]);
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [promoCodeToDelete, setPromoCodeToDelete] = useState<number | null>(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [stats, setStats] = useState({
      totalCodes: 0,
      usedCodes: 0,
      availableCodes: 0,
      totalValue: 0
    });
  
    const fetchPromoCodes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(API_URL + '/promo-codes-all-admin', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPromoCodes(data.data);
        setFilteredCodes(data.data);
        updateStats(data.data);
      } catch (error) {
        console.error('Error fetching promo codes:', error);
        setError('Failed to load promo codes. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    const updateStats = (codes: PromoCode[]) => {
      setStats({
        totalCodes: codes.length,
        usedCodes: codes.filter(code => code.used).length,
        availableCodes: codes.filter(code => !code.used).length,
        totalValue: codes.reduce((acc, code) => acc + Number(code.value), 0)
      });
    };
  
    const handleSearch = (searchTerm: string) => {
      const filtered = promoCodes.filter(code => 
        code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        code.type.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCodes(filtered);
    };
  
    useEffect(() => {
      fetchPromoCodes();
    }, []);
  
    // Logique pour ouvrir le modal de suppression
    const openDeleteModal = (id: number) => {
      setPromoCodeToDelete(id);
      setIsDeleteModalOpen(true);
    };
  


    // Confirmation de suppression
    const confirmDeletePromoCode = async () => {
      if (promoCodeToDelete) {
        try {
          const response = await fetch(`${API_URL}/saved-promo-codes/${promoCodeToDelete}`, {
            method: 'DELETE',
          });
          const data = await response.json();
    
            await fetchPromoCodes(); // Rafraîchir les données
            setIsDeleteModalOpen(false);
            setPromoCodeToDelete(null);
     
        } catch (error) {
          console.error('Error deleting promo code:', error);
          setError('Failed to delete promo code. Please try again.');
        }
      }
    };
  
    // Ajout de code promo
    const handleAddPromoCode = async (promoCode: Partial<PromoCode>) => {
      try {
        const response = await fetch(API_URL + '/saved-promo-codes', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(promoCode),
        });
        const data = await response.json();
        if (data.status == "success") {
          await fetchPromoCodes(); // Rafraîchir les données
          setIsAddModalOpen(false);
          setError(null);
        } else {
          setError(data.message || 'Failed to add promo code');
        }
      } catch (error) {
        console.error('Error adding promo code:', error);
        setError('Failed to add promo code. Please try again.');
      }
    };
  
    if (loading) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23]">
          <div className="text-white">Loading...</div>
        </div>
      );
    }
  
    return (
      <div className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] p-6 min-h-screen">
        <div className="space-y-6">
          <StatsCards stats={stats} />
          
          <div className="flex justify-between items-center">
            <SearchBar onSearch={handleSearch} />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsAddModalOpen(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 transition-colors flex items-center gap-2"
            >
              <Plus size={20} /> Add Promo Code
            </motion.button>
          </div>
  
          <PromoCodeTable 
            promoCodes={filteredCodes}
            onDeleteClick={openDeleteModal}
          />
        </div>
  
        <AnimatePresence>
          {isAddModalOpen && (
            <SavePromoDialogAdmin
              isOpen={isAddModalOpen}
              onClose={() => setIsAddModalOpen(false)}
              onSave={handleAddPromoCode}
            />
          )}
        </AnimatePresence>
  
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setPromoCodeToDelete(null);
          }}
          onConfirm={confirmDeletePromoCode}
        />
      </div>
    );
  };

  export default PromoCodeAdmin;