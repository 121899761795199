import React, { useState, useEffect } from 'react';
import { 
  User, Mail, Camera, Save, 
  Upload, X, 
  ExternalLink,
  Check,
  Link
} from 'lucide-react';
import axios, { AxiosInstance } from 'axios';
import { API_URL } from './api_config';

interface Profile {
  kickUrl: any;
  username: string;
  email: string;
  pfp: string | null;
}

interface ApiResponse {
  message: string;
  file: {
    filename: string;
    originalname: string;
    mimetype: string;
    size: number;
    url: string;
  };
  user?: {
    username: string;
    email: string;
    pfp: string | null;
  };
}

const ProfileEditor: React.FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidatingKick, setIsValidatingKick] = useState<boolean>(false);
  const [kickUrlError, setKickUrlError] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [formData, setFormData] = useState<Profile>({
    username: '',
    email: '',
    pfp: null,
    kickUrl: null
  });
  const [error, setError] = useState<string | null>(null);

  const token = localStorage.getItem('token');
  const api: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async (): Promise<void> => {
    try {
      const response = await api.get<Profile>('/profile');
      setFormData({
        username: response.data.username || '',
        email: response.data.email || '',
        pfp: response.data.pfp || null,
        kickUrl: response.data.kickUrl || null,
      });
    } catch (err) {
      setError('Failed to load profile');
      console.error('Error loading profile:', err);
    }
  };

  const validateKickUrl = (url: string): boolean => {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname === 'kick.com' && urlObj.pathname.length > 1;
    } catch {
      return false;
    }
  };

  const handleKickUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      kickUrl: value
    }));
    setKickUrlError(null);
  };

  const handleValidateKick = async (): Promise<void> => {
    if (!formData.kickUrl) {
      setKickUrlError('Please enter a Kick URL');
      return;
    }

    if (!validateKickUrl(formData.kickUrl)) {
      setKickUrlError('Please enter a valid Kick URL (e.g., https://kick.com/username)');
      return;
    }

    setIsValidatingKick(true);
    try {
      await api.post('/profile/validate-kick', { kickUrl: formData.kickUrl });
      setKickUrlError(null);
    } catch (err) {
      setKickUrlError('Failed to validate Kick URL');
    } finally {
      setIsValidatingKick(false);
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      setIsSaving(true);
      const response = await api.put<ApiResponse>('/profile', formData);
      const updatedProfile: Profile = {
        username: response.data.user?.username || '',
        email: response.data.user?.email || '',
        pfp: response.data.user?.pfp || null,
        kickUrl: undefined
      };
      setFormData(updatedProfile);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError('Failed to update profile');
      console.error('Error updating profile:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      await handleProfilePictureUpload(file);
    }
  };

  const handleProfilePictureUpload = async (file: File): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('image', file);

      const uploadResponse = await api.post<ApiResponse>('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setFormData(prev => ({
        ...prev,
        pfp: uploadResponse.data.file.url || null
      }));
      setError(null);
    } catch (err) {
      setError('Failed to upload profile picture');
      console.error('Error uploading profile picture:', err);
    }
  };

  const handleDeleteProfilePicture = async (): Promise<void> => {
    try {
      await api.delete('/profile/pfp');
      setFormData(prev => ({
        ...prev,
        pfp: null
      }));
      setError(null);
    } catch (err) {
      setError('Failed to delete profile picture');
      console.error('Error deleting profile picture:', err);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) handleProfilePictureUpload(file);
  };

  return (
    <div className="w-full max-w-3xl mx-auto p-8 bg-gradient-to-br from-gray-900 to-[#1E1E1E] rounded-2xl shadow-xl border border-gray-800">
      {error && (
        <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500 flex items-center gap-2">
          <X size={18} />
          <span>{error}</span>
        </div>
      )}

      <div className="flex items-center justify-between mb-8">
        <h2 className="text-3xl font-bold text-white flex items-center gap-3">
          <User className="text-purple-500" size={32} />
          <span className="bg-gradient-to-r from-purple-400 to-purple-600 text-transparent bg-clip-text">
            Profile Editor
          </span>
        </h2>
        <button
          onClick={isEditing ? handleSave : () => setIsEditing(true)}
          className={`
            px-6 py-3 rounded-xl flex items-center gap-2 font-medium
            transition-all duration-300 hover:scale-105 shadow-lg
            ${isEditing 
              ? 'bg-gradient-to-r from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800' 
              : 'bg-gradient-to-r from-gray-700 to-gray-800 hover:from-gray-600 hover:to-gray-700'
            }
          `}
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <Upload size={20} className="text-white animate-spin" />
              <span className="text-white">Saving...</span>
            </>
          ) : (
            <>
              {isEditing ? (
                <>
                  <Save size={20} className="text-white" />
                  <span className="text-white">Save Changes</span>
                </>
              ) : (
                <>
                  <Camera size={20} className="text-white" />
                  <span className="text-white">Edit Profile</span>
                </>
              )}
            </>
          )}
        </button>
      </div>

      <div 
        className="flex justify-center mb-8"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="relative group">
          <div className={`
            w-40 h-40 rounded-full overflow-hidden
            border-4 transition-all duration-300
            ${isDragOver ? 'border-purple-500 scale-105' : 'border-purple-600'}
            ${isEditing ? 'group-hover:opacity-90 cursor-pointer' : ''}
            bg-gradient-to-br from-gray-800 to-gray-900
            flex items-center justify-center
            shadow-xl
          `}>
            {formData.pfp ? (
              <img 
                src={`https://empire-back.selaris.app${formData.pfp}`}
                alt="Profile" 
                className="w-full h-full object-cover"
              />
            ) : (
              <User size={52} className="text-purple-500" />
            )}
          </div>
          
          {isEditing && (
            <div className="absolute -bottom-2 right-0 flex gap-2">
              <label className="cursor-pointer p-3 bg-gradient-to-r from-purple-600 to-purple-700 rounded-full hover:from-purple-700 hover:to-purple-800 transition-colors shadow-lg">
                <Camera size={18} className="text-white" />
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileInputChange}
                />
              </label>
              {formData.pfp && (
                <button
                  onClick={handleDeleteProfilePicture}
                  className="p-3 bg-gradient-to-r from-red-500 to-red-600 rounded-full hover:from-red-600 hover:to-red-700 transition-colors shadow-lg"
                >
                  <X size={18} className="text-white" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {[
          { icon: User, label: 'Username', name: 'username' as const, type: 'text' },
          { icon: Mail, label: 'Email', name: 'email' as const, type: 'email' }
        ].map(({ icon: Icon, label, name, type }) => (
          <div
            key={name}
            className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-xl border border-gray-700/50 shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="flex items-center gap-3 mb-3">
              <Icon className="text-purple-500" size={20} />
              <label className="text-gray-400">{label}</label>
            </div>
            {isEditing ? (
              <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleInputChange}
                className="w-full bg-gray-900/50 text-white p-3 rounded-lg border border-gray-700 
                         focus:border-purple-500 outline-none transition-colors"
              />
            ) : (
              <p className="text-white font-medium">{formData[name]}</p>
            )}
          </div>
        ))}

        <div className="bg-gradient-to-br from-green-900/40 to-gray-900 p-6 rounded-xl border border-green-700/30 shadow-lg hover:shadow-xl transition-shadow">
          <div className="flex items-center gap-3 mb-3">
            <Link className="text-green-500" size={20} />
            <label className="text-gray-400">Kick Account</label>
          </div>
          {isEditing ? (
            <div className="space-y-4">
              <div className="flex gap-3">
                <input
                  type="url"
                  value={formData.kickUrl || ''}
                  onChange={handleKickUrlChange}
                  placeholder="https://kick.com/username"
                  className="flex-1 bg-gray-900/50 text-white p-3 rounded-lg border border-gray-700 
                           focus:border-green-500 outline-none transition-colors"
                />
                <button
                  onClick={handleValidateKick}
                  disabled={isValidatingKick}
                  className="px-4 py-2 bg-gradient-to-r from-green-600 to-green-700 
                           hover:from-green-700 hover:to-green-800 rounded-lg
                           flex items-center gap-2 transition-colors shadow-lg"
                >
                  {isValidatingKick ? (
                    <Upload className="animate-spin text-white" size={18} />
                  ) : (
                    <Check className="text-white" size={18} />
                  )}
                  <span className="text-white">Validate</span>
                </button>
              </div>
              {kickUrlError && (
                <p className="text-red-500 text-sm flex items-center gap-2">
                  <X size={14} />
                  {kickUrlError}
                </p>
              )}
            </div>
          ) : formData.kickUrl ? (
            <div className="flex items-center gap-3">
              <ExternalLink className="text-green-500" size={18} />
              <a 
                href={formData.kickUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-medium hover:text-green-400 transition-colors"
              >
                {formData.kickUrl}
              </a>
            </div>
          ) : (
            <p className="text-gray-500 italic">No Kick account linked</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileEditor;