import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, Link as LinkIcon, Copy, CheckCheck } from 'lucide-react';

interface UsefulLink {
  title: string;
  description: string;
  url: string;
  category: 'All' | 'Draw' | 'Stages' | 'Boxes' | 'Promo';
  icon?: React.ReactNode;
}

interface Profile {
  id: number;
  username: string;
  email: string;
  pfp: string | null;
  isAdmin: boolean;
  kickUrl: string | null;
  confirmed: boolean;
  createdAt: string;
  updatedAt: string;
}

const UsefulLinks: React.FC = () => {
  const [copiedLink, setCopiedLink] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await fetch('https://empire-back.selaris.app/api/profile', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        const data: Profile = await response.json();
        setProfile(data);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const links: UsefulLink[] = [
    {
      title: "Draw Animation & Card",
      description: "Route for the Draw Overlay",
      url: "https://front-empire.selaris.app/tirage",
      category: "Draw",
      icon: <LinkIcon className="text-blue-500" />
    },
    {
      title: "Boxes & Mode Selector",
      description: "Boxes & Mode Selector Overlay",
      url: "https://front-empire.selaris.app/lootbox",
      category: "Boxes",
      icon: <LinkIcon className="text-purple-500" />
    },
    {
      title: "Stage & Extra Life Overlay",
      description: "Stage Overlay and Extra Life Component",
      url: "https://front-empire.selaris.app/leaderboard",
      category: "Stages",
      icon: <LinkIcon className="text-green-500" />
    },
    {
      title: "Promo Code Inactive",
      description: "For OBS Browser Source, add this custom CSS:\nbody { margin: 0 !important; padding: 0 !important; background-color: rgba(0, 0, 0, 0) !important; -webkit-mask-image: -webkit-radial-gradient(white, black) !important; } \n#root, .fixed, div { -webkit-mask-image: -webkit-radial-gradient(white, black) !important; }",
      url: "https://front-empire.selaris.app/promocode-inactive",
      category: "Promo",
      icon: <LinkIcon className="text-yellow-500" />
    },
    {
      title: "Promo Code Active",
      description: "For OBS Browser Source, add this custom CSS:\nbody { margin: 0 !important; padding: 0 !important; background-color: rgba(0, 0, 0, 0) !important; -webkit-mask-image: -webkit-radial-gradient(white, black) !important; } \n#root, .fixed, div { -webkit-mask-image: -webkit-radial-gradient(white, black) !important; }",
      url: "https://front-empire.selaris.app/promocode-active",
      category: "Promo",
      icon: <LinkIcon className="text-yellow-500" />
    }
  ];

  const categories = Array.from(new Set(links.map(link => link.category)));

  const getUrlWithUsername = (baseUrl: string) => {
    if (profile?.username) {
      return `${baseUrl}?user=${profile.username}`;
    }
    return baseUrl;
  };

  const copyToClipboard = async (url: string) => {
    try {
      await navigator.clipboard.writeText(getUrlWithUsername(url));
      setCopiedLink(url);
      setTimeout(() => setCopiedLink(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const filteredLinks = selectedCategory 
    ? links.filter(link => link.category === selectedCategory)
    : links;

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <ExternalLink className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Useful Links
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Quick access to important overlays and resources
              </p>
            </div>
          </div>
        </div>

        {/* Category Filters */}
        <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-8">
          <div className="flex flex-wrap gap-3">
            <motion.button
              onClick={() => setSelectedCategory(null)}
              className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                !selectedCategory 
                  ? 'bg-violet-600 text-white shadow-lg shadow-violet-500/20' 
                  : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700'
              }`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              All
            </motion.button>
            {categories.map((category) => (
              <motion.button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-6 py-3 rounded-xl text-sm font-medium transition-all duration-200 ${
                  selectedCategory === category 
                    ? 'bg-violet-600 text-white shadow-lg shadow-violet-500/20' 
                    : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700'
                }`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {category}
              </motion.button>
            ))}
          </div>
        </div>

        {/* Links Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <AnimatePresence mode="popLayout">
            {filteredLinks.map((link) => (
              <motion.div
                key={link.url}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
              >
                <div className="p-6 border-l-4 border-violet-500">
                  <div className="relative z-10">
                    <div className="flex items-start justify-between">
                      <div className="flex-1">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-violet-500/10 rounded-lg">
                            {link.icon}
                          </div>
                          <h3 className="text-lg font-semibold text-white">{link.title}</h3>
                        </div>
                        <p className="text-gray-400 text-sm mt-3 whitespace-pre-line">{link.description}</p>
                      </div>
                      <motion.button
                        onClick={() => copyToClipboard(link.url)}
                        className="p-2 hover:bg-violet-500/20 rounded-lg transition-colors ml-4"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        {copiedLink === link.url ? (
                          <CheckCheck className="text-emerald-400" size={20} />
                        ) : (
                          <Copy className="text-gray-400" size={20} />
                        )}
                      </motion.button>
                    </div>
                    <div className="flex items-center space-x-2 mt-4 bg-black/20 rounded-lg p-3">
                      <span className="text-sm text-gray-400 font-mono flex-1">
                        {getUrlWithUsername(link.url)}
                      </span>
                      <motion.a
                        href={getUrlWithUsername(link.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 hover:bg-violet-500/20 rounded-lg transition-colors"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <ExternalLink size={16} className="text-violet-400" />
                      </motion.a>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default UsefulLinks;