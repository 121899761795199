import { API_URL } from "components/api_config";
import { motion } from "framer-motion";
import { Eye, EyeOff, Play, Power, Sparkles } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const ConfettiControl: React.FC = () => {
  const [confettiStatus, setConfettiStatus] = useState(false);
  
  const fetchConfettiStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/confetti-hidden`);
      const data = await response.json();
      if (data && data.status === "success") {
        setConfettiStatus(!data.data.is_active);
      }
    } catch (error) {
      console.error('Failed to fetch Confetti status:', error);
      toast.error('Failed to fetch Confetti status');
    }
  };

  useEffect(() => {
    fetchConfettiStatus();
  }, []);

  const startConfettiAnimation = async () => {
    const loadingToast = toast.loading('Starting confetti animation...');
    try {
      const response = await fetch(`${API_URL}/confetti-hidden`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_active: true })
      });

      if (response.ok) {
        setConfettiStatus(false);
        toast.success('Confetti animation started!', { id: loadingToast });
        await new Promise(resolve => setTimeout(resolve, 10000));
        setConfettiStatus(true);
      } else {
        throw new Error('Failed to start confetti animation');
      }
    } catch (error) {
      console.error('Error starting confetti animation:', error);
      toast.error('Failed to start confetti animation', { id: loadingToast });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
    >
      <div className="p-6 border-l-4 border-yellow-500">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="p-3 bg-yellow-500/10 rounded-xl">
              <Sparkles 
                className={confettiStatus ? 'text-yellow-500' : 'text-gray-500'}
                size={24}
              />
            </div>
            <div className="ml-4">
              <h2 className="text-white font-semibold text-lg">Confetti Animation</h2>
              <p className="text-gray-400 text-sm">Trigger celebration effects</p>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <button
              onClick={startConfettiAnimation}
              disabled={!confettiStatus}
              className={`flex items-center px-6 py-3 rounded-xl transition-all duration-200
                ${confettiStatus 
                  ? 'bg-yellow-500 hover:bg-yellow-600 cursor-pointer' 
                  : 'bg-gray-600 cursor-not-allowed'
                } text-white font-medium transform hover:scale-105 active:scale-95
                shadow-lg ${confettiStatus ? 'shadow-yellow-500/20' : 'shadow-gray-600/20'}`}
            >
              <Play className="mr-2" size={18} />
              Start Animation
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};


  export const ExtraLifeHideControl: React.FC = () => {
    const [hideStatus, setHideStatus] = useState(false);
    
    const fetchHideStatus = async () => {
      try {
        const response = await fetch(`${API_URL}/extra-life-hide`);
        const data = await response.json();
        setHideStatus(!data.is_active);
      } catch (error) {
        console.error('Failed to fetch Extra Life hide status:', error);
      }
    };
  
    useEffect(() => {
      fetchHideStatus();
      const interval = setInterval(fetchHideStatus, 2000);
      return () => clearInterval(interval);
    }, []);
  
    const toggleHideStatus = async () => {
      const loadingToast = toast.loading(`${hideStatus ? 'Showing' : 'Hiding'} Extra Life overlay...`);
      try {
        const response = await fetch(`${API_URL}/extra-life-hide`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ is_active: hideStatus })
        });
  
        if (response.ok) {
          const data = await response.json();
          setHideStatus(!data.is_active);
          toast.success(`Extra Life overlay ${hideStatus ? 'shown' : 'hidden'} successfully`, { id: loadingToast });
        } else {
          throw new Error('Failed to toggle hide status');
        }
      } catch (error) {
        console.error('Error toggling hide status:', error);
        toast.error('Failed to toggle hide status', { id: loadingToast });
      }
    };
  
    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
      >
        <div className="p-6 border-l-4 border-blue-500">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="p-3 bg-blue-500/10 rounded-xl">
                {hideStatus ? (
                  <EyeOff className="text-gray-500" size={24} />
                ) : (
                  <Eye className="text-blue-500" size={24} />
                )}
              </div>
              <div className="ml-4">
                <h2 className="text-white font-semibold text-lg">Extra Life Overlay</h2>
                <p className="text-gray-400 text-sm">Toggle overlay visibility</p>
              </div>
            </div>
            
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleHideStatus}
                className={`flex items-center px-6 py-3 rounded-xl transition-all duration-200
                  ${hideStatus 
                    ? 'bg-emerald-500 hover:bg-emerald-600' 
                    : 'bg-red-500 hover:bg-red-600'
                  } text-white font-medium transform hover:scale-105 active:scale-95
                  shadow-lg ${hideStatus ? 'shadow-emerald-500/20' : 'shadow-red-500/20'}`}
              >
                <Power className="mr-2" size={18} />
                {hideStatus ? 'Show Overlay' : 'Hide Overlay'}
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };