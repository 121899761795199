import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trophy } from 'lucide-react';
import axios from 'axios';

interface Winner {
  timestamp: string | number | Date;
  isActive: boolean;
  id: number;
  username: string;
  avatar: string;
}

interface WinnerResponse {
  success: boolean;
  data: Winner | null;
  message: string;
}

export const LastWinner: React.FC = () => {
  const [winner, setWinner] = useState<Winner | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWinner = async () => {
      try {
        const response = await axios.get<WinnerResponse>('https://empire-back.selaris.app/api/winner');
        setWinner(response.data.data);
      } catch (err) {
        setError('Failed to load winner');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWinner();
    const interval = setInterval(fetchWinner, 5000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <motion.div
        className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center justify-center text-gray-400">
          <span>Loading...</span>
        </div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center justify-center text-red-400">
          <span>{error}</span>
        </div>
      </motion.div>
    );
  }

  if (!winner) {
    return (
      <motion.div
        className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="flex items-center justify-center text-gray-400">
          <Trophy className="mr-2 text-violet-400" size={20} />
          <span>No previous winner</span>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="flex items-center mb-4">
        <div className="p-3 bg-violet-500/10 rounded-xl ring-1 ring-violet-500/20">
          <Trophy className="text-violet-400" size={20} />
        </div>
        <h2 className="ml-3 text-lg font-semibold text-white">Last Winner</h2>
      </div>
      
      <div className="flex items-center space-x-4">
        <motion.div 
          className="w-12 h-12 rounded-xl overflow-hidden border-2 border-violet-500/20"
          whileHover={{ scale: 1.05 }}
        >
          <img 
            src={winner.avatar} 
            alt={winner.username} 
            className="w-full h-full object-cover"
          />
        </motion.div>
        <div className="flex flex-col">
          <span className="text-white font-medium">{winner.username}</span>
          <span className="text-sm text-gray-400">
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default LastWinner;