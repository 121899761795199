import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Sidebar from 'components/Layouts/Sidebar';
import Header from 'components/Layouts/Header';
import EmailWhitelist from 'components/Admin/WhitelistEmail';
import PromoCodeAdmin from 'components/Admin/PromoCodeAdmin';


const PromoCodeAdminRoute: React.FC<{
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}> = ({ isSidebarOpen, setIsSidebarOpen, isMobile }) => {
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <AnimatePresence>
        {(isSidebarOpen || !isMobile) && (
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="z-20"
          >
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </motion.div>
        )}
      </AnimatePresence>

      <div className="flex-1 flex flex-col overflow-hidden ">
        <Header 
          toggleSidebar={toggleSidebar} 
          isSidebarOpen={isSidebarOpen}
          isMobile={isMobile}
        />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gradient-to-b from-[#0E1015] to-[#1A1C23] hide-scrollbar">
            <br />
            <PromoCodeAdmin />
        </main>
      </div>
    </div>
  );
};

export default PromoCodeAdminRoute;