import React, { useState, useEffect } from 'react';
import { Trophy, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';

interface WinnerHistory {
  id: number;
  winnerName: string;
  pfp: string;
  timestamp: string;
  isActive: boolean;
}

export const WinnerHistory: React.FC = () => {
  const [winners, setWinners] = useState<WinnerHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const totalPages = Math.ceil(winners.length / ITEMS_PER_PAGE);
  const paginatedWinners = winners.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await fetch('https://empire-back.selaris.app/api/history-winner');
        if (!response.ok) throw new Error('Failed to fetch winners');
        const data = await response.json();
        setWinners(data);
      } catch (err) {
        console.error('Error fetching winners:', err);
        setError('Failed to load winners');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWinners();
    const intervalId = setInterval(fetchWinners, 4000);
    return () => clearInterval(intervalId);
  }, []);

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`https://empire-back.selaris.app/api/history-winner/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete winner');
      
      setWinners(prev => prev.filter(winner => winner.id !== id));
      const newTotalPages = Math.ceil((winners.length - 1) / ITEMS_PER_PAGE);
      if (currentPage > newTotalPages) setCurrentPage(Math.max(1, newTotalPages));
    } catch (err) {
      console.error('Error deleting winner:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-700 rounded w-1/3 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-20 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl border border-gray-800/50">
        <div className="flex items-center justify-center text-red-400 py-2">
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
      <div className="max-w-4xl mx-auto">
        <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <div className="p-3 bg-violet-500/10 rounded-xl ring-1 ring-violet-500/20">
                <Trophy className="w-6 h-6 text-violet-400" />
              </div>
              <div className="ml-4">
                <h2 className="text-xl font-semibold text-white">Winner History</h2>
                <p className="text-gray-400 text-sm">
                  {winners.length} winner{winners.length !== 1 ? 's' : ''}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            {paginatedWinners.map((winner) => (
              <div
                key={winner.id}
                className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="w-12 h-12 rounded-xl overflow-hidden border-2 border-violet-500/20">
                      <img
                        src={winner.pfp}
                        alt={winner.winnerName}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <div className="text-white font-medium">{winner.winnerName}</div>
                      <div className="text-sm text-gray-400">
                        {new Date(winner.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleDelete(winner.id)}
                    className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="flex items-center justify-center mt-6 space-x-2">
              <button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                className={`p-2 rounded-lg ${
                  currentPage === 1 
                    ? 'text-gray-500 cursor-not-allowed' 
                    : 'text-violet-400 hover:bg-violet-500/20'
                }`}
              >
                <ChevronLeft size={20} />
              </button>

              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={`w-8 h-8 rounded-lg ${
                    currentPage === page
                      ? 'bg-violet-500 text-white'
                      : 'text-gray-400 hover:bg-violet-500/20'
                  }`}
                >
                  {page}
                </button>
              ))}

              <button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
                className={`p-2 rounded-lg ${
                  currentPage === totalPages 
                    ? 'text-gray-500 cursor-not-allowed' 
                    : 'text-violet-400 hover:bg-violet-500/20'
                }`}
              >
                <ChevronRight size={20} />
              </button>
            </div>
          )}
        </div>
      </div>

  );
};

export default WinnerHistory;