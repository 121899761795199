import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Users, Trash2, ChevronLeft, ChevronRight, AlertCircle } from 'lucide-react';

interface Participant {
  id: number;
  username: string;
  avatar: string;
}

interface ParticipantsCardProps {
  participants: Participant[];
  onDelete: (id: number) => Promise<void>;
  isDeleteLoading: number | null;
  addToast: (message: string, type: 'success' | 'error') => void;
}

export const ParticipantsCard: React.FC<ParticipantsCardProps> = ({ 
  participants, 
  onDelete, 
  isDeleteLoading, 
  addToast 
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isClearingAll, setIsClearingAll] = useState(false);
  const ITEMS_PER_PAGE = 4;

  const filteredParticipants = participants.filter(participant =>
    participant.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredParticipants.length / ITEMS_PER_PAGE);
  const currentParticipants = filteredParticipants.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const handleClearAll = async () => {
    setIsClearingAll(true);
    try {
      await Promise.all(participants.map(p => onDelete(p.id)));
      addToast('All participants have been removed', 'success');
      setIsConfirmOpen(false);
    } catch (error) {
      addToast('Failed to remove all participants', 'error');
    } finally {
      setIsClearingAll(false);
    }
  };

  return (
    <div className="min-h-screen">
      <div className="max-w-4xl mx-auto">
        <motion.div
          className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="p-6 border-b border-gray-800/50">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div className="p-3 bg-violet-500/10 rounded-xl ring-1 ring-violet-500/20">
                  <Users className="w-6 h-6 text-violet-400" />
                </div>
                <div className="ml-4">
                  <h2 className="text-xl font-semibold text-white">Participants</h2>
                  <p className="text-gray-400 text-sm">
                    {filteredParticipants.length} participant{filteredParticipants.length !== 1 ? 's' : ''}
                  </p>
                </div>
              </div>
            </div>

            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-violet-400" size={18} />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-black/20 text-white border border-violet-500/20 rounded-xl py-2 px-4 pl-10 
                         focus:outline-none focus:border-violet-500 transition-all"
                placeholder="Search participants..."
              />
            </div>
          </div>

          <div className="p-6">
            {filteredParticipants.length === 0 ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center justify-center py-8 text-gray-400"
              >
                <AlertCircle className="mr-2" size={20} />
                <span>{searchTerm ? 'No participants found' : 'No participants yet'}</span>
              </motion.div>
            ) : (
              <div className="space-y-3">
                <AnimatePresence mode="popLayout">
                  {currentParticipants.map((participant) => (
                    <motion.div
                      key={participant.id}
                      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-4
                               hover:border-violet-500/20 transition-all"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      layout
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="w-10 h-10 rounded-xl overflow-hidden border-2 border-violet-500/20">
                            <img
                              src={participant.avatar}
                              alt={participant.username}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <span className="text-white font-medium">{participant.username}</span>
                        </div>
                        <motion.button
                          onClick={() => onDelete(participant.id)}
                          className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors
                                   disabled:opacity-50"
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          disabled={isDeleteLoading === participant.id || isClearingAll}
                        >
                          <Trash2 size={18} />
                        </motion.button>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            )}
          </div>

          {totalPages > 1 && (
            <div className="flex items-center justify-center p-6 border-t border-gray-800/50">
              <motion.button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                className="p-2 text-violet-400 hover:bg-violet-500/20 rounded-lg disabled:text-gray-600"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                disabled={currentPage === 1}
              >
                <ChevronLeft size={20} />
              </motion.button>
              <span className="mx-4 text-white">
                {currentPage} / {totalPages}
              </span>
              <motion.button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                className="p-2 text-violet-400 hover:bg-violet-500/20 rounded-lg disabled:text-gray-600"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                disabled={currentPage === totalPages}
              >
                <ChevronRight size={20} />
              </motion.button>
            </div>
          )}
        </motion.div>
      </div>

      <AnimatePresence>
        {isConfirmOpen && (
          <motion.div
            className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => !isClearingAll && setIsConfirmOpen(false)}
          >
            <motion.div
              className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 max-w-sm w-full"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              onClick={e => e.stopPropagation()}
            >
              <h3 className="text-xl font-semibold text-white mb-4">Clear All Participants?</h3>
              <p className="text-gray-400 mb-6">
                Are you sure you want to remove all participants? This action cannot be undone.
              </p>
              <div className="flex gap-3">
                <motion.button
                  onClick={handleClearAll}
                  className="flex-1 bg-red-500/90 hover:bg-red-500 text-white py-2.5 rounded-lg font-medium
                           transition-colors disabled:opacity-50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isClearingAll}
                >
                  {isClearingAll ? 'Clearing...' : 'Clear All'}
                </motion.button>
                <motion.button
                  onClick={() => !isClearingAll && setIsConfirmOpen(false)}
                  className="flex-1 bg-violet-500/20 hover:bg-violet-500/30 text-white py-2.5 rounded-lg
                           font-medium transition-colors disabled:opacity-50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isClearingAll}
                >
                  Cancel
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ParticipantsCard;