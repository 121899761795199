import React, { useEffect, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Mail,
  Shield,
  Search,
  Trash,
  Edit,
  X,
  Save,
  XCircle,
  Plus,
  CheckCircle,
  AlertTriangle
} from 'lucide-react';

// Mock data for initial testing
const mockEmails = [
  { id: 1, email: "john@example.com", isWhitelisted: true, createdAt: "2024-01-15T10:30:00" },
  { id: 2, email: "sarah@company.com", isWhitelisted: false, createdAt: "2024-02-20T14:20:00" },
  { id: 3, email: "dev@startup.io", isWhitelisted: true, createdAt: "2024-03-01T09:15:00" },
];

interface Email {
    id: number;
    email: string;
    isWhitelisted: boolean;
    createdAt: string;
  }
  
  interface Stats {
    total: number;
    whitelisted: number;
    blocked: number;
  }
  
  interface ApiResponse<T> {
    success: boolean;
    data: T;
    meta?: {
      total: number;
      page: number;
      limit: number;
      totalPages: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    message?: string;
  }
  

interface Email {
  id: number;
  email: string;
  isWhitelisted: boolean;
  createdAt: string;
}

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ 
  isOpen, 
  onClose, 
  onConfirm 
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
          >
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="bg-red-500/10 p-4 rounded-full">
                <Trash className="text-red-400" size={40} />
              </div>
              
              <div>
                <h2 className="text-xl font-semibold text-white mb-2">
                  Delete Email
                </h2>
                <p className="text-gray-400 text-sm">
                  Are you sure you want to remove this email from the list? 
                  This action cannot be undone.
                </p>
              </div>

              <div className="flex space-x-4 w-full pt-4">
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClose}
                  className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-center gap-2"
                >
                  <XCircle size={20} /> Cancel
                </motion.button>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onConfirm}
                  className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors flex items-center justify-center gap-2"
                >
                  <Trash size={20} /> Delete
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};




const PromoCodeAdmin: React.FC = () => {
    const [emails, setEmails] = useState<Email[]>([]);
    const [stats, setStats] = useState<Stats>({ total: 0, whitelisted: 0, blocked: 0 });
    const [loading, setLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState<string>('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [emailToDelete, setEmailToDelete] = useState<number | null>(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newEmailForm, setNewEmailForm] = useState({
      email: '',
      isWhitelisted: true
    });
    const [error, setError] = useState<string | null>(null);


    const fetchData = async () => {
      try {
        console.log("test");

        const [emailsResponse, statsResponse] = await Promise.all([
          fetch(`https://empire-back.selaris.app/api/emails?page=${page}&limit=${limit}&status=${selectedFilter}&search=${searchQuery}`),
          fetch('https://empire-back.selaris.app/api/emails/stats')
        ]);
  
        const emailsData: ApiResponse<Email[]> = await emailsResponse.json();
        const statsData: ApiResponse<Stats> = await statsResponse.json();
  
        if (emailsData.success && statsData.success) {
          setEmails(emailsData.data);
          setStats(statsData.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, [page, limit, selectedFilter, searchQuery]);
  
    // Email validation helper
    const isValidEmail = (email: string) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };
  
    const openDeleteModal = (id: number) => {
      setEmailToDelete(id);
      setIsDeleteModalOpen(true);
    };
  
    const confirmDeleteEmail = async () => {
      if (emailToDelete) {
        try {
          const response = await fetch(`https://empire-back.selaris.app/api/emails/${emailToDelete}`, {
            method: 'DELETE'
          });
          
          const data = await response.json();
          
          if (data.success) {
            await fetchData(); // Refresh the data
            setIsDeleteModalOpen(false);
            setEmailToDelete(null);
          } else {
            setError(data.message || 'Failed to delete email');
          }
        } catch (error) {
          console.error('Error deleting email:', error);
          setError('Failed to delete email. Please try again.');
        }
      }
    };
  
    const handleAddEmail = async () => {
      try {
        if (!isValidEmail(newEmailForm.email)) {
          setError('Please enter a valid email address');
          return;
        }
  
        const response = await fetch('https://empire-back.selaris.app/api/emails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newEmailForm)
        });
  
        const data = await response.json();
  
        if (data.success) {
          await fetchData(); // Refresh the data
          setIsAddModalOpen(false);
          setNewEmailForm({ email: '', isWhitelisted: true });
          setError(null);
        } else {
          setError(data.message || 'Failed to add email');
        }
      } catch (error) {
        console.error('Error adding email:', error);
        setError('Failed to add email. Please try again.');
      }
    };
  
    const filteredEmails = useMemo(() => {
        return emails.filter((email) => {
          const matchesFilter =
            selectedFilter === 'all' ||
            (selectedFilter === 'whitelisted' && email.isWhitelisted) ||
            (selectedFilter === 'blocked' && !email.isWhitelisted);
      
          const matchesSearch =
            email.email.toLowerCase().includes(searchQuery.toLowerCase());
      
          return matchesFilter && matchesSearch;
        });
      }, [emails, selectedFilter, searchQuery]);

    const toggleWhitelist = async (id: number) => {
      try {
        const emailToUpdate = emails.find(e => e.id === id);
        if (!emailToUpdate) return;
  
        const response = await fetch(`https://empire-back.selaris.app/api/emails/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            isWhitelisted: !emailToUpdate.isWhitelisted
          })
        });
  
        const data = await response.json();
  
        if (data.success) {
          await fetchData(); // Refresh the data
        } else {
          setError(data.message || 'Failed to update whitelist status');
        }
      } catch (error) {
        console.error('Error toggling whitelist status:', error);
        setError('Failed to update whitelist status. Please try again.');
      }
    };
  
    if (loading) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23]">
          <div className="text-white">Loading...</div>
        </div>
      );
    }

  return (
    <div className="bg-gradient-to-b from-[#0E1015] to-[#1A1C23] p-6 min-h-screen">
    <div className="space-y-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <motion.div 
          whileHover={{ y: -2 }}
          className="bg-gradient-to-br from-indigo-500/10 to-indigo-500/5 p-4 rounded-xl border border-indigo-500/20"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-indigo-500/10 rounded-lg">
              <Mail className="h-5 w-5 text-indigo-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Emails</p>
              <p className="text-xl font-semibold text-white">{stats.total}</p>
            </div>
          </div>
        </motion.div>

        <motion.div 
          whileHover={{ y: -2 }}
          className="bg-gradient-to-br from-green-500/10 to-green-500/5 p-4 rounded-xl border border-green-500/20"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-green-500/10 rounded-lg">
              <CheckCircle className="h-5 w-5 text-green-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Whitelisted</p>
              <p className="text-xl font-semibold text-white">{stats.whitelisted}</p>
            </div>
          </div>
        </motion.div>

        <motion.div 
          whileHover={{ y: -2 }}
          className="bg-gradient-to-br from-yellow-500/10 to-yellow-500/5 p-4 rounded-xl border border-yellow-500/20"
        >
          <div className="flex items-center gap-3">
            <div className="p-2 bg-yellow-500/10 rounded-lg">
              <Shield className="h-5 w-5 text-yellow-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Blocked</p>
              <p className="text-xl font-semibold text-white">{stats.blocked}</p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Search and Filters */}
      <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search emails..."
            className="w-full pl-10 pr-4 py-2 bg-gray-800/50 rounded-lg text-gray-300 
                    placeholder-gray-500 focus:outline-none focus:ring-2 
                    focus:ring-indigo-500/50 focus:bg-gray-800/80 transition-all"
          />
        </div>

        <div className="flex gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsAddModalOpen(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 
                    transition-colors flex items-center gap-2"
          >
            <Plus size={20} /> Add Email
          </motion.button>
          
          {['all', 'whitelisted', 'blocked'].map((filter) => (
            <button
              key={filter}
              onClick={() => setSelectedFilter(filter)}
              className={`px-4 py-2 rounded-lg capitalize transition-all
                ${selectedFilter === filter 
                  ? 'bg-indigo-500/20 text-indigo-400 ring-1 ring-indigo-500/50' 
                  : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'}`}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>

      {/* Emails Table */}
      <div className="bg-gray-800/50 rounded-xl overflow-hidden border border-gray-700/50">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-700/50">
              <th className="text-left p-4 text-gray-400 font-medium">Email</th>
              <th className="text-left p-4 text-gray-400 font-medium">Status</th>
              <th className="text-left p-4 text-gray-400 font-medium">Created At</th>
              <th className="text-right p-4 text-gray-400 font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmails.map((email) => (
              <motion.tr 
                key={email.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ backgroundColor: 'rgba(255,255,255,0.02)' }}
                className="border-b border-gray-700/50 last:border-0"
              >
                <td className="p-4">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-full bg-gray-700 flex items-center justify-center">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <span className="text-gray-200">{email.email}</span>
                  </div>
                </td>
                <td className="p-4">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => toggleWhitelist(email.id)}
                    className={`px-3 py-1 rounded-full text-xs font-medium ${
                      email.isWhitelisted 
                        ? 'bg-green-400/10 text-green-400' 
                        : 'bg-yellow-400/10 text-yellow-400'
                    }`}
                  >
                    {email.isWhitelisted ? 'Whitelisted' : 'Blocked'}
                  </motion.button>
                </td>
                <td className="p-4 text-gray-400">
                  {new Date(email.createdAt).toLocaleDateString()}
                </td>
                <td className="p-4">
                  <div className="flex items-center gap-2 justify-end">
                    <motion.button 
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => openDeleteModal(email.id)}
                      className="text-red-400 hover:text-red-300 transition-colors"
                    >
                      <Trash size={20} />
                    </motion.button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
      <AnimatePresence>
        {isAddModalOpen && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gray-900 rounded-xl p-6 w-full max-w-md shadow-2xl border border-gray-700"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-white">Add New Email</h2>
                <motion.button 
                  whileHover={{ rotate: 90 }}
                  onClick={() => {
                    setIsAddModalOpen(false);
                    setError(null);
                  }}
                  className="text-gray-400 hover:text-white"
                >
                  <X size={24} />
                </motion.button>
              </div>

              {error && (
                <div className="mb-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                  <p className="text-red-400 text-sm">{error}</p>
                </div>
              )}

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">Email Address</label>
                  <input 
                    type="email" 
                    value={newEmailForm.email}
                    onChange={(e) => setNewEmailForm({...newEmailForm, email: e.target.value})}
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    placeholder="Enter email address"
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <input 
                    type="checkbox" 
                    checked={newEmailForm.isWhitelisted}
                    onChange={(e) => setNewEmailForm({...newEmailForm, isWhitelisted: e.target.checked})}
                    className="form-checkbox h-4 w-4 text-indigo-600 bg-gray-800 border-gray-700 rounded"
                  />
                  <span className="text-gray-300">Add to whitelist</span>
                </div>
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsAddModalOpen(false);
                    setError(null);
                  }}
                  className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center gap-2"
                >
                  <XCircle size={20} /> Cancel
                </motion.button>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleAddEmail}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 transition-colors flex items-center gap-2"
                >
                  <Save size={20} /> Save
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setEmailToDelete(null);
        }}
        onConfirm={confirmDeleteEmail}
      />
    </div>
  );
};

export default PromoCodeAdmin;