import React, { useState, useEffect } from 'react';
import { Bot, ChevronRight, Save, AlertCircle, Shield, Plus, Trash2, Terminal } from 'lucide-react';
import axios from 'axios';
import { API_URL } from 'components/api_config';

const BotConfig = () => {
  const [currentTab, setCurrentTab] = useState('general');
  const [isLoading, setIsLoading] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [settings, setSettings] = useState({
    prefix: '',
    permissions: [] as string[],
    commands: [
      { name: '!mode', description: 'Display all mode' },
      { name: '!crazy', description: 'Choose Crazy Mode' },
      { name: '!degen', description: 'Choose Degen Mode' },
      { name: '!terminal', description: 'Choose Terminal Mode' },
      { name: '!normal', description: 'Choose Normal Mode' },
      { name: '!<keyword>', description: 'Join current giveaway' }
    ]
  });
  const [newCommand, setNewCommand] = useState({ name: '', description: '' });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const prefixResponse = await axios.get(`${API_URL}/bot/prefix`);
        const usersResponse = await axios.get(`${API_URL}/bot/users`);

        setSettings({
          prefix: prefixResponse.data.prefix,
          permissions: usersResponse.data.map((user: { username: string }) => user.username),
          commands: settings.commands
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleAddUsername = async () => {
    if (!newUsername.trim() || settings.permissions.includes(newUsername.trim())) return;

    try {
      await axios.post(`${API_URL}/bot/users`, { username: newUsername.trim() });
      setSettings((prev) => ({
        ...prev,
        permissions: [...prev.permissions, newUsername.trim()],
      }));
      setNewUsername('');
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleRemoveUsername = async (username: string) => {
    try {
      await axios.delete(`${API_URL}/bot/users/${username}`);
      setSettings((prev) => ({
        ...prev,
        permissions: prev.permissions.filter((u) => u !== username),
      }));
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  const handleAddCommand = () => {
    if (!newCommand.name.trim() || !newCommand.description.trim()) return;
    
    setSettings(prev => ({
      ...prev,
      commands: [...prev.commands, { 
        name: newCommand.name.trim(),
        description: newCommand.description.trim()
      }]
    }));
    setNewCommand({ name: '', description: '' });
  };

  const handleRemoveCommand = (commandName: string) => {
    setSettings(prev => ({
      ...prev,
      commands: prev.commands.filter(cmd => cmd.name !== commandName)
    }));
  };

  const handleSaveSettings = async () => {
    try {
      await axios.put(`${API_URL}/bot/prefix`, { prefix: settings.prefix });
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const menuItems = [
    { id: 'general', icon: Bot, label: 'General' },
    { id: 'permission', icon: Shield, label: 'User Mods' },
    { id: 'commands', icon: Terminal, label: 'Bot Commands' }
  ];

  const TabContent = () => {
    if (currentTab === 'commands') {
      return (
        <div className="space-y-6">
          <div className="space-y-4">
          
            
            <div className="space-y-2">
              {settings.commands.map((command) => (
                <div 
                  key={command.name}
                  className="flex items-center justify-between p-4 bg-gray-800/30 rounded-lg border border-gray-700"
                >
                  <div className="flex-1">
                    <div className="text-violet-400 font-mono">{command.name}</div>
                    <div className="text-gray-400 text-sm mt-1">{command.description}</div>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    
    if (currentTab === 'general') {
      return (
        <div className="space-y-6">
          <div className="space-y-4">
            <label className="block">
              <span className="text-sm font-medium text-gray-200 mb-1 block">
                Command Prefix
              </span>
              <input
                type="text"
                value={settings.prefix}
                onChange={(e) =>
                  setSettings((prev) => ({ ...prev, prefix: e.target.value }))
                }
                maxLength={3}
                className="w-24 px-3 py-2 bg-gray-800/50 border border-gray-600 rounded-lg 
                          focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-violet-500 
                          text-white transition-all duration-200 hover:bg-gray-800/70"
              />
            </label>
          </div>
        </div>
      );
    }

    if (currentTab === 'permission') {
      return (
        <div className="space-y-6">
          <div className="space-y-4">
            <label className="block">
              <span className="text-sm font-medium text-gray-200 mb-1 block">
                Authorized Users
              </span>
              <div className="flex gap-2 mb-4">
                <input
                  type="text"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                  placeholder="Enter username"
                  className="flex-1 px-3 py-2 bg-gray-800/50 border border-gray-600 rounded-lg 
                            focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-violet-500 
                            text-white transition-all duration-200 hover:bg-gray-800/70"
                />
                <button
                  onClick={handleAddUsername}
                  className="px-3 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-500 
                           transition-all duration-200 flex items-center gap-2"
                >
                  <Plus className="w-4 h-4" />
                  Add
                </button>
              </div>
            </label>
            
            <div className="space-y-2">
              {settings.permissions.map((username) => (
                <div 
                  key={username}
                  className="flex items-center justify-between p-3 bg-gray-800/30 rounded-lg border border-gray-700"
                >
                  <span className="text-gray-200">{username}</span>
                  <button
                    onClick={() => handleRemoveUsername(username)}
                    className="p-1 text-gray-400 hover:text-red-400 transition-colors duration-200"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6 text-gray-100">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Bot className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Bot Configuration
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Manage the EmpireBot settings and preferences
              </p>
            </div>
          </div>
        </div>

        {showSuccess && (
          <div className="transform translate-y-0 opacity-100 transition-all duration-300 bg-violet-500/10 border border-violet-500/30 text-violet-200 px-6 py-4 rounded-xl flex items-center gap-3">
            <AlertCircle className="w-5 h-5 text-violet-400" />
            Settings saved successfully!
          </div>
        )}

        <div className="flex gap-6">
          <div className="w-64 rounded-xl overflow-hidden backdrop-blur-xl bg-gray-900/30 border border-gray-800/50">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setCurrentTab(item.id)}
                className={`w-full flex items-center justify-between px-6 py-4 transition-all duration-200
                  ${
                    currentTab === item.id
                      ? 'bg-violet-600 text-white shadow-lg shadow-violet-500/20'
                      : 'text-gray-300 hover:bg-gray-800/50 hover:text-white'
                  }`}
              >
                <div className="flex items-center gap-3">
                  <item.icon className="w-5 h-5" />
                  <span className="font-medium">{item.label}</span>
                </div>
                <ChevronRight className={`w-4 h-4 transition-transform duration-200 
                  ${currentTab === item.id ? 'rotate-90' : ''}`} />
              </button>
            ))}
          </div>

          <div className="flex-1 rounded-xl backdrop-blur-xl bg-gray-900/30 border border-gray-800/50">
            <div className="p-8">
              <TabContent />
            </div>

            <div className="p-6 border-t border-gray-800/50 bg-black/20">
              <button
                onClick={handleSaveSettings}
                disabled={isLoading}
                className={`flex items-center px-6 py-3 bg-violet-600 text-white rounded-lg
                          hover:bg-violet-500 focus:outline-none focus:ring-2 focus:ring-violet-500 
                          focus:ring-offset-2 focus:ring-offset-gray-900 disabled:opacity-50 
                          transition-all duration-200 transform hover:scale-105 active:scale-95
                          ${isLoading ? 'animate-pulse' : ''}`}
              >
                <Save className="w-5 h-5 mr-2" />
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotConfig;